import { createStyles, FormGroup, makeStyles, Theme } from "@material-ui/core";
import {
  CheckboxField,
  InputField,
} from "src/components/formik_material_inputs";

import { provider_fields } from "./fields";
import * as Yup from "yup";
import ServicesFormGroup from "../shared/services-form-group";

enum SignupSteps {
  CONTACT_INFO = 0,
  BIO,
}
const SignupStepsKeys = Object.keys(SignupSteps).filter((v) =>
  isNaN(v as any)
) as Array<string>;

const LastSignupStep = SignupSteps.BIO;

const StepFormMap = {
  [SignupSteps.CONTACT_INFO]: ContactForm,
  [SignupSteps.BIO]: BioForm,
};

const { email, name, phone, refer } = provider_fields;
export function ContactForm() {
  return (
    <FormGroup>
      <InputField autoFocus type="email" autoComplete="email" {...email} />
      <InputField autoComplete="name" {...name} />
      <InputField autoComplete="tel" {...phone} />
      <InputField {...refer} />
    </FormGroup>
  );
}
ContactForm.form_fields = [email, name, phone, refer];

const { bio, serv, agreed } = provider_fields;

const serviceFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    tos: {
      "& a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
    },
  })
);

export function BioForm() {
  const classes = serviceFormStyles();

  return (
    <>
      <ServicesFormGroup field={serv} />
      <FormGroup>
        <InputField multiline {...bio} />
        <CheckboxField {...agreed} className={classes.tos} />
      </FormGroup>
    </>
  );
}

BioForm.form_fields = [serv, bio, agreed];

export function validationSchemeForStep(step: SignupSteps) {
  const field_names = (StepFormMap[step].form_fields as Array<any>).map(
    (f) => f.name
  );

  return Yup.object().shape(
    Object.entries(provider_fields).reduce((acc, v) => {
      if ("test" in v[1] && field_names.indexOf(v[1].name) > -1) {
        acc[v[0]] = v[1].test;
      }
      return acc;
    }, {})
  );
}

export function initialValuesForStep() {
  // const field_names = (StepFormMap[step].form_fields as Array<any>).map(f => f.name)
  // We don't want to filter initial values because that messes up Formik in case we switch between steps

  return Object.entries(provider_fields).reduce((acc, v) => {
    if ("initial_value" in v[1]) {
      acc[v[0]] = v[1].initial_value;
    } else {
      acc[v[0]] = "";
    }
    return acc;
  }, {});
}

export { SignupSteps, LastSignupStep, SignupStepsKeys, StepFormMap };
