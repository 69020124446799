import {
  Button,
  CircularProgress,
  createStyles,
  Divider,
  fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Slide,
  Step,
  StepButton,
  Stepper,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  ArrowBackIos,
  LocalFloristOutlined,
  MonetizationOnOutlined,
  PublicOutlined,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { useEffect, useState } from "react";
import {
  initialValuesForStep,
  LastSignupStep,
  SignupSteps,
  validationSchemeForStep,
  SignupStepsKeys,
  StepFormMap,
} from "src/components/signup/provider/forms";

import { navigateToApp } from "src/redirect_to_app";
import logo from "src/images/logo.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
      "& .Mui-error": {
        fontSize: "1em",
      },
      "& .MuiAlert-standardError": {
        justifyContent: "center",
      },
    },
    panels: {
      display: "flex",
      height: "100%",
      width: "100%",
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
      },
      "& .panel": {
        // height: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .left": {
        display: (props) => (props.left_panel_visible ? `` : `none`),
        flexDirection: "column",
        backgroundColor: theme.palette.primary.main,
        color: "white",
        [theme.breakpoints.down("md")]: {
          paddingTop: 34,
        },
        [theme.breakpoints.up("md")]: {
          width: (props: any) => (props.left_panel_visible ? "50%" : "100%"),
        },
      },
      "& .right": {
        // height: '100%',
        [theme.breakpoints.up("md")]: {
          width: (props: any) => (props.left_panel_visible ? "50%" : "100%"),
        },
      },
    },
    back_link: {
      position: "absolute",
      top: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      padding: 5,
      color: "inherit",
      textDecoration: "inherit",
    },
    logo: {
      maxHeight: "36px",
      maxWidth: "140px",
      marginTop: "auto",
    },
    right_panel_div: {
      padding: "1em",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
    },
    right_root: {
      "& .MuiFormGroup-root": {
        alignItems: "center",
      },
      "& .MuiFormControl-root": {
        maxWidth: "50ch",
        width: "100%",
      },
    },
    right_logo: {
      maxHeight: "108px",
      padding: 24,
      marginTop: "auto",
    },
    toggle_group: {
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "1em",
      "& svg": {
        fill: fade(theme.palette.action.active, 0.38),
        stroke: fade(theme.palette.action.active, 0.38),
      },
      "& .Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "& svg": {
          fill: "white",
          stroke: "white",
        },
      },
      "& .Mui-selected:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
      "& .MuiToggleButton-label": {
        display: "block",
      },
      "& button.MuiToggleButton-root": {
        border: "1px solid #00000030",
        borderRadius: 5,
      },
    },
    list_icon: {
      color: theme.palette.success.light,
      minWidth: "2em",
    },
  })
);

export default function Signup() {
  const [left_panel_visible, setLeftPanelVisible] = useState(true);

  const [activeStep, setActiveStep] = useState(SignupSteps.CONTACT_INFO);
  const [signup_error, setSignupError] = useState("");

  useEffect(() => {
    if (activeStep === SignupSteps.CONTACT_INFO) {
      setLeftPanelVisible(true);
    } else {
      setLeftPanelVisible(false);
    }
  }, [activeStep, setLeftPanelVisible]);

  const handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    if (activeStep === LastSignupStep) {
      setSignupError("");
      const resp = await fetch("/api/auth/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          agreed: true,
          signup_type: "WORK",
          ...values,
        }),
      });
      const json = await resp.json();

      if (json.success) {
        navigateToApp();
        actions.resetForm();
      } else {
        setSignupError(json.reason);
      }

      actions.setSubmitting(false);
    } else {
      setActiveStep((step) => step + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const validationSchema = validationSchemeForStep(activeStep);
  const initialValues = initialValuesForStep();
  const ActiveStepForm = StepFormMap[activeStep];

  const classes = useStyles({ left_panel_visible });
  return (
    <div className={classes.root}>
      <div className={classes.panels}>
        <div className="panel left">
          <a className={classes.back_link} href="/">
            <ArrowBackIos /> Back Home
          </a>
          <Typography variant="h3" align="center">
            Apply to join our
            <br />
            <Typography variant="h1" align="center" component="span">
              Talent Pool
            </Typography>
          </Typography>
          <div>
            <List>
              {[
                [
                  <PublicOutlined />,
                  <>
                    Work for <strong>Top Organizations</strong> globally
                  </>,
                ],
                [
                  <LocalFloristOutlined />,
                  <>
                    Exclusively for the <strong>Development sector</strong>
                  </>,
                ],
                [
                  <MonetizationOnOutlined />,
                  <>
                    Invoicing + <strong>Payments</strong> — Built In
                  </>,
                ],
              ].map(([icon, text], idx) => (
                <ListItem key={idx}>
                  <ListItemIcon className={classes.list_icon}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText>{text}</ListItemText>
                </ListItem>
              ))}
            </List>
            {/* 
            <ul>
              <li>
                <PublicOutlined /> Work with Top Organizations globally
              </li>
              <li>
                <LocalFloristOutlined /> Exclusively in the development sector
              </li>
              <li>
                <MonetizationOnOutlined /> Contracting, Invoicing, Payments —
                Built In
              </li>
            </ul> */}
          </div>
        </div>
        <div className="panel right">
          {!left_panel_visible && (
            <a className={classes.back_link} href="/">
              <ArrowBackIos /> Back Home
            </a>
          )}
          <div className={classes.right_panel_div}>
            <div className={classes.right_root}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form id="signup-form">
                    <div>
                      <Slide in={activeStep === SignupSteps.CONTACT_INFO}>
                        <img className={classes.right_logo} src={logo} />
                      </Slide>

                      <Slide
                        direction="up"
                        in={activeStep !== SignupSteps.CONTACT_INFO}
                        unmountOnExit
                      >
                        <Stepper
                          style={{ marginTop: -108 }}
                          activeStep={
                            isSubmitting ? activeStep + 1 : activeStep
                          }
                          alternativeLabel
                        >
                          {SignupStepsKeys.map((step) => (
                            <Step key={step}>
                              <StepButton
                                onClick={() => setActiveStep(SignupSteps[step])}
                              >
                                {step.replace("_", " ")}
                              </StepButton>
                            </Step>
                          ))}
                        </Stepper>
                      </Slide>
                    </div>

                    <ActiveStepForm />
                    {signup_error && (
                      <Alert severity="error">{signup_error}</Alert>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "1.5em",
                      }}
                    >
                      {activeStep > 0 && activeStep <= LastSignupStep && (
                        <Button
                          color="primary"
                          variant="outlined"
                          id="next-input"
                          onClick={() => setActiveStep((step) => step - 1)}
                          disabled={isSubmitting}
                        >
                          Previous
                        </Button>
                      )}

                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        id="next-input"
                        disabled={isSubmitting}
                      >
                        {activeStep === LastSignupStep ? "Signup" : "Next"}
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            style={{ position: "absolute" }}
                          />
                        )}
                      </Button>
                    </div>

                    {activeStep === SignupSteps.CONTACT_INFO && (
                      <>
                        <Divider
                          style={{ margin: "2em", borderColor: "#0000000a" }}
                        />
                        <Typography variant="body2" align="center">
                          Already have an account?{" "}
                          <Button variant="outlined" size="small" href="/">
                            Login
                          </Button>
                        </Typography>
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
